import React, {Component} from 'react';
import {Header} from './Header';
// import file_ic from '../assets/images/file-ic.svg';
import {Footer} from './Footer';
import down_arrow from '../assets/images/download-arrow.png';
import clinic_support from '../assets/images/clinical-support.svg';
import tech_support from '../assets/images/technical-support.svg';
import {supportPageText as supportPageText_en} from "../libs/en/texts-support-page-lib";
import {supportPageText as supportPageText_de} from "../libs/de/texts-support-page-lib";
import {supportPageText as supportPageText_fr} from "../libs/fr/texts-support-page-lib";
import {supportPageText as supportPageText_nl} from "../libs/nl/texts-support-page-lib";
import {supportPageText as supportPageText_it} from "../libs/it/texts-support-page-lib";
import {supportPageText as supportPageText_cz} from "../libs/cz/texts-support-page-lib";
import {supportPageText as supportPageText_fi} from "../libs/fi/texts-support-page-lib";
import {supportPageText as supportPageText_pl} from "../libs/pl/texts-support-page-lib";
import {supportPageText as supportPageText_se} from "../libs/se/texts-support-page-lib";
import {supportPageText as supportPageText_no} from "../libs/no/texts-support-page-lib";
import {supportPageText as supportPageText_dk} from "../libs/dk/texts-support-page-lib";
import {supportPageText as supportPageText_es} from "../libs/es/texts-support-page-lib";
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

export class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            supportPageText: supportPageText_en,
        };
        if (this.state.language === "DE") {
            this.state.supportPageText = supportPageText_de;
        }
        if ((this.state.language === "FR") || (this.state.language === "FR-FR")) {
            this.state.supportPageText = supportPageText_fr;
        }
        if (this.state.language === "NL") {
            this.state.supportPageText = supportPageText_nl;
        }
        if (this.state.language === "IT") {
            this.state.supportPageText = supportPageText_it;
        }
        if (this.state.language === "CZ") {
            this.state.supportPageText = supportPageText_cz;
        }
        if (this.state.language === "FI") {
            this.state.supportPageText = supportPageText_fi;
        }
        if (this.state.language === "PL") {
            this.state.supportPageText = supportPageText_pl;
        }
        if (this.state.language === "SE") {
            this.state.supportPageText = supportPageText_se;
        }
        if (this.state.language === "NO") {
            this.state.supportPageText = supportPageText_no;
        }
        if (this.state.language === "DK") {
            this.state.supportPageText = supportPageText_dk;
        }
        if (this.state.language === "ES") {
            this.state.supportPageText = supportPageText_es;
        }

        this.state.isDanaSupport = false;
        this.state.isYpsoSupport = false;
        this.state.isSupportChosen = false;
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_en,
                });
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_de,
                });
            }
            if ((this.props.language === "FR") || (this.props.language === "FR-FR")) {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_fr,
                });
            }
            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_nl,
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_it,
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_cz,
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_fi,
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_pl,
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_se,
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_no,
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_dk,
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    supportPageText: supportPageText_es,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.supportPageText.title)}</title>
                    <meta name="title" content={parse(this.state.supportPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.supportPageText.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.supportPageText.localeMeta}/>
                    <meta name="keywords" content={this.state.supportPageText.keywords}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>{parse(this.state.supportPageText.needHelp)}</h1>
                                <p>{parse(this.state.supportPageText.needHelpResponse)} </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-content-box">
                    {/*<section className="blue-content-box inner-blue-box">*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="support-inner">
                                    <span className="col-img"><img src={clinic_support} alt="icon"/></span>
                                    <h2>{parse(this.state.supportPageText.clinicalSupportHeader)}</h2>
                                    <p>{parse(this.state.supportPageText.clinicalSupportText)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="support-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12
                            {/*content-wrap*/}
                            ">
                                <div className="support-inner">
                                    <span className="col-img"><img src={tech_support} alt="icon"/></span>
                                    <h2>{parse(this.state.supportPageText.techSupportHeader)}</h2>
                                    <p>{parse(this.state.supportPageText.techSupportHeaderText)} </p>
                                </div>
                                <div className="sub-info-col">
                                    <div className="info-col">
                                        <h3>{parse(this.state.supportPageText.techMaterialHeader)}</h3>
                                        <h4>{parse(this.state.supportPageText.userManual)}</h4>
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_commercial.pdf"
                                           title={parse(this.state.supportPageText.userManualEnglish_mmol_l)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.userManualEnglish_mmol_l)}</a>
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_cs_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualCzech_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualCzech_mmol_l)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_da_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualDanish_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualDanish_mmol_l)}</a>
                                        }
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_de_commercial.pdf"
                                           title={parse(this.state.supportPageText.userManualGerman_mmol_l)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.userManualGerman_mmol_l)}</a>
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mgdl_es_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualSpanish_mg_dl)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualSpanish_mg_dl)}</a>
                                        }
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_fr_commercial.pdf"
                                           title={parse(this.state.supportPageText.userManualFrench_mmol_l)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.userManualFrench_mmol_l)}</a>
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_it_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualItalian_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualItalian_mmol_l)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_nl_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualDutch_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualDutch_mmol_l)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mgdl_no_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualNorsk_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualNorsk_mmol_l)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mgdl_pl_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualPolish_mg_dl)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualPolish_mg_dl)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_fi_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualFinish_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualFinish_mmol_l)}</a>
                                        }
                                        {
                                            <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_sv_commercial.pdf"
                                               title={parse(this.state.supportPageText.userManualSwedish_mmol_l)}
                                               className="download-link"><img src={down_arrow}
                                                                              alt="ic"/>
                                                {parse(this.state.supportPageText.userManualSwedish_mmol_l)}</a>
                                        }
                                        <a href="https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/99+CamAPS+FX+previous+IFUs.zip"
                                           title={parse(this.state.supportPageText.previousManualVersions)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.previousManualVersions)}</a>
                                        {/*<h4>Installation guide</h4>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        <h4>{parse(this.state.supportPageText.installationYpsoPumpHeader)}</h4>
                                        <div className="info-link">
                                            <a
                                                href={parse(this.state.supportPageText.installationYpsoPumpLink)}
                                                title={parse(this.state.supportPageText.installationYpsoPumpText)}
                                                target="_blank" rel="noopener noreferrer">
                                                {parse(this.state.supportPageText.installationYpsoPumpText)} </a>
                                        </div>
                                        <h3>
                                        </h3>
                                        <h4>{parse(this.state.supportPageText.installationManualHeader)}</h4>
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf"
                                           title={parse(this.state.supportPageText.installationManualEnglish)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.installationManualEnglish)}</a>
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX+DE.pdf"
                                           title={parse(this.state.supportPageText.installationManualGerman)}
                                           className="download-link"><img
                                            src={down_arrow}
                                            alt="ic"/>
                                            {parse(this.state.supportPageText.installationManualGerman)}</a>
                                        <a href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX+FR.pdf"
                                           title={parse(this.state.supportPageText.installationManualFrench)}
                                           className="download-link"><img src={down_arrow}
                                                                          alt="ic"/>
                                            {parse(this.state.supportPageText.installationManualFrench)}</a>
                                    </div>

                                    <div className="info-col">

                                        <h3>{parse(this.state.supportPageText.educationalMaterial)}</h3>
                                        <h4>{parse(this.state.supportPageText.onlineTraining)}</h4>
                                        <div className="info-link">
                                            <a
                                                href={parse(this.state.supportPageText.onlineTrainingLink)}
                                                title={parse(this.state.supportPageText.onlineTrainingText_1)}
                                                target="_blank" rel="noopener noreferrer">
                                                {parse(this.state.supportPageText.onlineTrainingText_1)} </a>
                                        </div>
                                        {(this.state.language !== "FR-FR") &&
                                        <div>
                                            <h3>
                                            </h3>
                                            <h4>{parse(this.state.supportPageText.webinarsHeading)}</h4>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/m0t_Sc5MEp4"
                                                    title={parse(this.state.supportPageText.webinar_1)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_1)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://www.youtube.com/watch?v=H-dKGB08SSM"
                                                    title={parse(this.state.supportPageText.webinar_2)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_2)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://www.youtube.com/watch?v=2PUWySDCAbk"
                                                    title={parse(this.state.supportPageText.webinar_3)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_3)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/r7V1V0wVDOQ"
                                                    title={parse(this.state.supportPageText.webinar_4)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_4)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/Gwomzu_5udI"
                                                    title={parse(this.state.supportPageText.webinar_5)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_5)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/pKQArzzv5QA"
                                                    title={parse(this.state.supportPageText.webinar_6)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_6)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/vUgrVtqksyA"
                                                    title={parse(this.state.supportPageText.webinar_7)}
                                                    target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.supportPageText.webinar_7)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/KASU0BHAThk"
                                                    title={parse(this.state.supportPageText.webinar_8)}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {parse(this.state.supportPageText.webinar_8)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/J0mzjyMxqw0"
                                                    title={parse(this.state.supportPageText.webinar_9)}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {parse(this.state.supportPageText.webinar_9)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/IwVcDbygQLk"
                                                    title={parse(this.state.supportPageText.webinar_10)}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {parse(this.state.supportPageText.webinar_10)}</a>
                                            </div>
                                            <div className="info-link">
                                                <a
                                                    href="https://youtu.be/fWPA2KPC0bg"
                                                    title={parse(this.state.supportPageText.webinar_11)}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {parse(this.state.supportPageText.webinar_11)}</a>
                                            </div>
                                            <div className="info-link">
                                                {parse(this.state.supportPageText.webinar_footnote)}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="info-col">
                                        <h3>
                                        </h3>
                                        <h3>{parse(this.state.supportPageText.contactDetailsHeader)}</h3>

                                        {
                                            <div className="info-link">
                                                <a
                                                    href={parse(this.state.supportPageText.ypsoPumpSupportLink)}
                                                    title={parse(this.state.supportPageText.ypsoPumpSupportText)}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {parse(this.state.supportPageText.ypsoPumpSupportText)} </a>
                                            </div>
                                        }

                                        <h3>
                                        </h3>
                                        {
                                            <div className="info-link">
                                                <a
                                                    href="/#"
                                                    title={parse(this.state.supportPageText.danaPumpSupportText)}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            isDanaSupport: !this.state.isDanaSupport,
                                                            isSupportChosen: true
                                                        })
                                                    }}><u>{parse(this.state.supportPageText.danaPumpSupportText)}</u></a>
                                            </div>
                                        }

                                        <h3>
                                        </h3>
                                        {this.state.isSupportChosen && this.state.isDanaSupport &&
                                        <div>
                                            <p>{parse(this.state.supportPageText.contactDetailsText)}</p>
                                            <div className="info-link">
                                                <a href={parse(this.state.supportPageText.contactDetailsEmailFull)}
                                                   title={parse(this.state.supportPageText.contactDetailsEmailTitle)}>
                                                    {parse(this.state.supportPageText.contactDetailsEmail)}</a>
                                                <a href={parse(this.state.supportPageText.contactDetailsPhoneFull)}
                                                   title={parse(this.state.supportPageText.contactDetailsPhoneTitle)}>
                                                    {parse(this.state.supportPageText.contactDetailsPhone)}</a>
                                            </div>
                                        </div>
                                        }

                                        {/*<h3>*/}
                                        {/*</h3>*/}
                                        {/*<div className="info-link">*/}
                                        {/*    <a*/}
                                        {/*        href={parse(this.state.supportPageText.statusPageLink)}*/}
                                        {/*        title={parse(this.state.supportPageText.statusPageText)}*/}
                                        {/*        target="_blank" rel="noopener noreferrer">*/}
                                        {/*        {parse(this.state.supportPageText.statusPageText)} </a>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="support-inner ">
                                    <h2>&nbsp;</h2>
                                    <p>{parse(this.state.supportPageText.eIfuAccess)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="support-sec">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-12 col-sm-12 content-wrap">*/}
                {/*                <div className="support-inner">*/}
                {/*                    <span className="col-img"><img src={training_ic} alt="icon"/></span>*/}
                {/*                    <h2>Training Support</h2>*/}
                {/*                    <p>Here you can find support material regarding any training available for CamAPS*/}
                {/*                        FX. </p>*/}
                {/*                </div>*/}
                {/*                <div className="sub-info-col">*/}
                {/*                    <div className="info-col">*/}
                {/*                        <h3>Training materials:</h3>*/}
                {/*                        <h4>Training Document One</h4>*/}
                {/*                        <a href="/#" title="Download" className="download-link"><img src={down_arrow}*/}
                {/*                                                                                     alt="ic"/>Download</a>*/}
                {/*                        <h4>Training Document Two</h4>*/}
                {/*                        <a href="/#" title="Download" className="download-link"><img src={down_arrow}*/}
                {/*                                                                                     alt="ic"/>Download</a>*/}
                {/*                    </div>*/}
                {/*                    <div className="info-col">*/}
                {/*                        <h3>Training links:</h3>*/}
                {/*                        <h4>Competency Checklist</h4>*/}
                {/*                        <a href="/#" title="Click Here" className="btn">Click Here</a>*/}
                {/*                        <h4>Training Video</h4>*/}
                {/*                        <a href="/#" title="Click Here" className="btn">Click Here</a>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <Footer props={this.props}/>
            </div>
        );
    }
}

