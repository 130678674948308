/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Support - CamAPS FX',
    titleMeta: 'Wir bieten breite Unterstützung für den Einsatz von CamAPS FX',
    descriptionMeta: 'Wir bieten unterstützendes Material wie Benutzerhandbücher, Download-Richtlinien, ' +
        'Webinare sowie Telefon- und E-Mail-Kundensupport.',
    keywords:
        'camaps support, camaps fx support, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'de_de',

    needHelp: 'Benötigen Sie Hilfe?',
    needHelpResponse: 'Wir hoffen, dass das unterstützende Material, das wir Ihnen hier zur Verfügung stellen, Ihnen dabei hilft, mögliche Probleme zu lösen. Wenn Sie keine Lösung für Ihr Problem finden, nehmen Sie bitte Kontakt mit uns auf.',
    clinicalSupportHeader: 'Klinischer Support',
    clinicalSupportText: 'Wenn Sie Fragen zu Ihrer Gesundheit haben, kontaktieren Sie bitte Ihr Diabetes-Team.',
    techSupportHeader: 'Technische Unterstützung und Schulungssupport',
    techSupportHeaderText: 'Hier finden Sie Supportmaterial und Kontaktdaten, falls Sie mit dem Support-Team Kontakt aufnehmen möchten.',
    techMaterialHeader: 'Technische Materialien:',
    userManual: 'CamAPS FX Bedienungsanleitung (PDF-Reader erforderlich)',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Vorherige Versionen',
    installationManualHeader: 'Installieren von CamAPS FX (DANA)',
    installationManualEnglish: 'Installation guide',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d’installation',
    educationalMaterial: 'Schulungsmaterial:',
    onlineTraining: 'Online Training:',
    onlineTrainingText: 'Zur Verfügung gestellt vom Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://de.camdiabtraining.com',
    onlineTrainingText_1: 'KOSTENLOSES zertifiziertes Online Training',
    webinarsHeading: 'Webinare',
    webinar_1: 'Einführung in das CamAPS FX System',
    webinar_2: 'Start des CamAPS FX Systems',
    webinar_3: 'Optimieren der CamAPS FX Einstellungen',
    webinar_4: 'Training für Lehrer und Schulungssupportmitarbeiter',
    webinar_5: 'Feinabstimmung, um die Zeit im Zielbereich zu verbessern',
    webinar_6: 'Einsatz der CamAPS FX während der Schwangerschaft',
    webinar_7: 'Einsatz der CamAPS FX bei Säuglingen, Kleinkindern und jungen Kindern',
    webinar_8: 'Einsatz der CamAPS FX bei jungen Erwachsenen und Erwachsenen',
    webinar_9: 'Management von körperlicher Aktivität, Mahlzeiten und anderen Lebensereignissen',
    webinar_10: 'Closed-Loop und Lebensqualität',
    webinar_11: 'Closed-Loop-Therapie & langjähriger Typ-1-Diabetes',
    webinar_footnote: '',

    contactDetailsHeader: 'Kontaktdetails:',
    contactDetailsText: 'Unser Support-Team steht während der Bürozeiten zur Verfügung, von Montag bis Freitag von 10 bis 18 Uhr.',
    contactDetailsEmailTitle: 'Mailen Sie uns',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Kontaktieren Sie uns',
    contactDetailsPhone: '+44 20 3695 3780 (Nur Englisch)',
    contactDetailsPhoneFull: 'tel:+442036953780',

    eIfuAccess: 'Druckversionen der Benutzerhandbücher können beim Kundenservice angefordert werden. ' +
        'Die Druckversionen sind gratis und werden innerhalb von ca. 3 bis 7 Tagen geliefert. ' +
        'Verwenden Sie den Adobe® Reader® zur optimalen Darstellung der Benutzerhandbücher ' +
        'von CamDiab.',

    statusPageText: 'Statusseite',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installieren von CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Benutzer von Dana-Pumpen',
    ypsoPumpSupportText: 'Benutzer von YpsoPump-Pumpen',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
