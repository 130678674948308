/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Podpora k aplikaci CamAPS FX',
    titleMeta: 'Uživatelům aplikace CamAPS FX nabízíme širokou podporu.',
    descriptionMeta: 'Poskytujeme podpůrné materiály včetně uživatelských příruček, pokynů pro stahování, webinářů a telefonické a e-mailové zákaznické podpory.',
    keywords:
        'podpora camaps, podpora camaps fx, ' ,
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'cs_cz',

    needHelp: 'Potřebujete pomoc?',
    needHelpResponse: 'Doufáme, že vám uvedené podpůrné materiály pomůžou vyřešit případné problémy. Pokud v nich nenajdete odpověď na váš problém, obraťte se na nás.',
    clinicalSupportHeader: 'Klinická podpora',
    clinicalSupportText: 'Máte-li dotazy týkající se vaší léčby, obraťte se na svého lékaře nebo zdravotní sestru.',
    techSupportHeader: 'Technická a vzdělávací podpora',
    techSupportHeaderText: 'Tady najdete podpůrné materiály a kontaktní údaje, pokud se budete někdy potřebovat spojit s naším týmem podpory.',
    techMaterialHeader: 'Technické materiály:',
    userManual: 'CamAPS FX uživatelská příručka',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Předchozí verze',
    installationManualHeader: 'Instalace aplikace CamAPS FX (DANA)',
    installationManualEnglish: 'Průvodce instalací',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Edukační materiály:',
    onlineTraining: 'Online školení:',
    onlineTrainingText: 'Poskytuje platforma Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'Certifikované online školení ZDARMA',
    webinarsHeading: 'Webináře',
    webinar_1: 'Seznámení se systémem CamAPS FX',
    webinar_2: 'Začínáme používat systém CamAPS FX',
    webinar_3: 'Optimalizace nastavení systému CamAPS FX',
    webinar_4: 'Školení pro učitele a vzdělávací pracovníky',
    webinar_5: 'Úprava nastavení a prodloužení času v cílovém rozmezí',
    webinar_6: 'Používání aplikace CamAPS FX v těhotenství',
    webinar_7: 'Používání aplikace CamAPS FX u kojenců, batolat a malých dětí',
    webinar_8: 'Používání aplikace CamAPS FX u dospívajících a dospělých',
    webinar_9: 'Řízení fyzické aktivity, stravování a dalších životních událostí',
    webinar_10: 'Léčba inzulinem v uzavřené smyčce a kvalita života',
    webinar_11: '¤Léčba v uzavřené smyčce a dlouhotrvající diabetes 1. typu',
    webinar_footnote: '',

    contactDetailsHeader: 'Kontakty:',
    contactDetailsText: 'Náš tým podpory je k dispozici od pondělí do pátku v pracovní době od 10 do 18 hodin.',
    contactDetailsEmailTitle: 'Pošlete nám e-mail',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Zavolejte nám',
    contactDetailsPhone: '+44 020 369 537 80 (pouze anglicky)',
    contactDetailsPhoneFull: 'tel:+442036953780',

    eIfuAccess: 'Tištěné kopie uživatelských příruček si můžete vyžádat od zákaznické podpory. Tištěné kopie jsou zdarma a dorazí přibližně za 3 až 7 dní. Pro nejlepší výsledky použijte Adobe® Reader® k zobrazení uživatelských příruček CamDiab.',

    statusPageText: 'Stavová stránka',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Instalace aplikace CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Uživatelé pumpy Dana',
    ypsoPumpSupportText: 'Uživatelé pumpy YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/cs-CZ/servis/linka-pece-o-zakazniky-a-kontakty.html',
};
