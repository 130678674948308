/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Wsparcie — aplikacja CamAPS FX',
    titleMeta: 'Gwarantujemy kompleksowe wsparcie w zakresie użytkowania aplikacji CamAPS FX.',
    descriptionMeta: 'Zapewniamy materiały pomocnicze, w tym podręczniki użytkownika i wytyczne do pobrania, a także oferujemy webinary oraz pomoc techniczną za pośrednictwem kontaktu telefonicznego i przez pocztę e-mail.',
    keywords:
        'obsługa cammaps, obsługa camaps fx,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'pl_pl',

    needHelp: 'Potrzebujesz pomocy?',
    needHelpResponse: 'Mamy nadzieję, że materiały pomocnicze dostępne tutaj pomogą Ci w rozwiązaniu problemów, które napotykasz. Jeśli nie możesz znaleźć rozwiązania swojego problemu, skontaktuj się z nami.',
    clinicalSupportHeader: 'Wsparcie kliniczne',
    clinicalSupportText: 'Jeśli masz jakiekolwiek pytania dotyczące opieki zdrowotnej, skontaktuj się z naszym zespołem ds. leczenia cukrzycy.',
    techSupportHeader: 'Wsparcie techniczne i edukacyjne',
    techSupportHeaderText: 'Tutaj możesz znaleźć materiały pomocnicze i dane kontaktowe, jeśli potrzebujesz skontaktować się z naszym zespołem ds. pomocy technicznej.',
    techMaterialHeader: 'Materiały techniczne:',
    userManual: 'CamAPS FX podręcznik użytkownika',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Poprzednie wersje',
    installationManualHeader: 'Instalacja aplikacji CamAPS FX (DANA)',
    installationManualEnglish: 'Instrukcja instalacji',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Materiały edukacyjne:',
    onlineTraining: 'Szkolenie online:',
    onlineTrainingText: 'Zapewniane w ramach programu Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'BEZPŁATNE certyfikowane szkolenie online',
    webinarsHeading: 'Webinary',
    webinar_1: 'Wprowadzenie do systemu CamAPS FX',
    webinar_2: 'Uruchamianie systemu CamAPS FX',
    webinar_3: 'Optymalizacja ustawień aplikacji CamAPS FX',
    webinar_4: 'Szkolenie dla nauczycieli i personelu pomocniczego w placówkach edukacyjnych',
    webinar_5: 'Precyzyjna regulacja w celu wydłużenia czasu, w którym stężenie glukozy we krwi mieści się w zakresie docelowym (Time in range)',
    webinar_6: 'Korzystanie z aplikacji CamAPS FX podczas ciąży',
    webinar_7: 'Stosowanie aplikacji CamAPS FX u niemowląt i małych dzieci',
    webinar_8: 'Stosowanie aplikacji CamAPS FX u nastolatków i osób dorosłych',
    webinar_9: 'Zarządzanie aktywnością fizyczną, posiłkami i innymi zdarzeniami w życiu',
    webinar_10: 'Insulinoterapia w pętli zamkniętej i jakość życia',
    webinar_11: 'Terapia w pętli zamkniętej i przewlekła cukrzyca typu 1.',
    webinar_footnote: '',

    contactDetailsHeader: 'Dane kontaktowe:',
    contactDetailsText: 'Nasz zespół ds. pomocy technicznej jest dostępny w godzinach roboczych od 10:00 do 18:00 od poniedziałku do piątku.',
    contactDetailsEmailTitle: 'Wyślij do nas wiadomość e-mail',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Skontaktuj się z nami',
    contactDetailsPhone: '+44 20 3695 3780 (tylko angielski)',
    contactDetailsPhoneFull: 'tel.: +442036953780',

    eIfuAccess: 'Drukowane kopie instrukcji obsługi można uzyskać od działu obsługi klienta. Wydrukowane egzemplarze są bezpłatne i dotrą za około 3 do 7 dni. Aby uzyskać najlepsze wyniki, użyj programu Adobe® Reader® do przeglądania podręczników użytkownika CamDiab.',

    statusPageText: 'Strona stanu',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Instalacja aplikacji CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Użytkownicy pomp Dana',
    ypsoPumpSupportText: 'Użytkownicy pomp YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/pl-PL/uslugi/dzial-obslugi-klientow-i-linia-pomocy.html',
};
