/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Asistencia - CamAPS FX',
    titleMeta: 'Ofrecemos una amplia asistencia en el uso de CamAPS FX',
    descriptionMeta: 'Proporcionamos material de apoyo, como manuales del usuario, instrucciones de descarga y webinarios, así como asistencia al cliente por teléfono y correo electrónico.',
    keywords:
        'compatibilidad con camaps, compatibilidad con camaps fx,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'es_es',

    needHelp: '¿Necesita ayuda?',
    needHelpResponse: 'Esperamos que el material de apoyo que aquí suministramos le ayude a aclarar cualquier problema que pueda tener. Si no encuentra respuesta a su problema, póngase en contacto con nosotros.',
    clinicalSupportHeader: 'Asistencia clínica',
    clinicalSupportText: 'Si tiene preguntas sobre su atención sanitaria, diríjase a su equipo de atención a la diabetes.',
    techSupportHeader: 'Asistencia técnica y formativa',
    techSupportHeaderText: 'Aquí encontrará material de apoyo y datos de contacto, por si necesita dirigirse nuestro equipo de asistencia.',
    techMaterialHeader: 'Materiales técnicos:',
    userManual: 'CamAPS FX manual del usuario',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Versión anterior',
    installationManualHeader: 'Instalación de CamAPS FX (DANA)',
    installationManualEnglish: 'Guía de instalación',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Material educativo:',
    onlineTraining: 'Formación en línea:',
    onlineTrainingText: 'Facilitada por el Programa de Formación en Diabetes de Cambridge (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'Formación GRATUITA en línea certificada',
    webinarsHeading: 'Webinarios',
    webinar_1: 'Presentación del sistema CamAPS FX',
    webinar_2: 'Iniciar el sistema CamAPS FX',
    webinar_3: 'Optimizar los ajustes de CamAPS FX',
    webinar_4: 'Formación para instructores y personal de asistencia a la formación',
    webinar_5: 'Ajuste preciso para mejorar el tiempo dentro del intervalo',
    webinar_6: 'Uso de CamAPS FX durante el embarazo',
    webinar_7: 'Uso de CamAPS FX en bebés, lactantes mayores y niños pequeños',
    webinar_8: 'Uso de CamAPS FX en jóvenes y adultos',
    webinar_9: 'Gestión de la actividad física, de las comidas y de otros aspectos de la vida',
    webinar_10: 'Insulinoterapia de Loop cerrado (automático) y calidad de vida',
    webinar_11: 'Terapia de Loop cerrado (automático) y diabetes de tipo 1 de larga duración',
    webinar_footnote: '',

    contactDetailsHeader: 'Datos de contacto:',
    contactDetailsText: 'Nuestro equipo de asistencia está disponible durante el horario laboral de lunes a viernes, de 10 a 18 h.',
    contactDetailsEmailTitle: 'Envíenos un correo electrónico',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Póngase en contacto con nosotros',
    contactDetailsPhone: '+44 20 3695 3780 (solo inglés)',
    contactDetailsPhoneFull: 'tel.:+442036953780',

    eIfuAccess: 'Se pueden solicitar copias impresas de los manuales de usuario a Atención al cliente. Las copias impresas son gratuitas y llegarán en aproximadamente 3 a 7 días. Para obtener los mejores resultados, utilice Adobe® Reader® para ver los manuales de usuario de CamDiab.',

    statusPageText: 'Página de estado',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Instalación de CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Usuarios de bombas Dana',
    ypsoPumpSupportText: 'Usuarios de bombas YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/es-ES/servicios/servicio-y-asistencia.html',
};
