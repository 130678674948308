/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Ondersteuning - CamAPS FX',
    titleMeta: 'Wij bieden uitgebreide ondersteuning voor het gebruik van CamAPS FX',
    descriptionMeta: 'Wij bieden ondersteuning in de vorm van gebruikershandleidingen, downloadinstructies, webinars en klantondersteuning via de telefoon en per e-mail.',
    keywords:
        'camaps support, camaps fx support, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nl_NL',

    needHelp: 'Heeft u hulp nodig?',
    needHelpResponse: 'Wij hopen dat de hier beschikbaar gestelde materialen u helpen met eventuele kwesties waar u tegenaan loopt. Als u geen antwoord kunt vinden voor uw probleem, neem dan alstublieft contact op.',
    clinicalSupportHeader: 'Klinische ondersteuning',
    clinicalSupportText: 'Heeft u vragen over uw zorg, neem dan contact op met uw diabetesbehandelaar.',
    techSupportHeader: 'Technische ondersteuning en scholing',
    techSupportHeaderText: 'Hier vindt u materialen ter ondersteuning en de contactgegevens voor als u in contact wilt komen met ons ondersteuningsteam.',
    techMaterialHeader: 'Technische materialen:',
    userManual: 'CamAPS FX gebruikershandleiding',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Vorige versies',
    installationManualHeader: 'Installeren CamAPS FX (DANA)',
    installationManualEnglish: 'Installatiehandleiding',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Educatief materiaal:',
    onlineTraining: 'Online training:',
    onlineTrainingText: 'Beschikbaar gesteld door het Cambridge Diabetes Education Programme (CDEP, het opleidingsprogramma van Cambridge op het gebied van diabetes)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'GRATIS online een training van een gecertificeerde trainer',
    webinarsHeading: 'Webinars',
    webinar_1: 'Inleiding tot het CamAPS FX-systeem',
    webinar_2: 'Beginnen met het CamAPS FX-systeem',
    webinar_3: 'Optimaliseren van de CamAPS FX-instellingen',
    webinar_4: 'Opleiding voor docenten en onderwijsondersteunend personeel',
    webinar_5: 'Fijne afstelling voor het verbeteren van de tijd binnen het doelbereik',
    webinar_6: 'Gebruik van CamAPS FX tijdens de zwangerschap',
    webinar_7: 'Gebruik van CamAPS FX bij pasgeborenen, peuters, kleuters en jonge kinderen',
    webinar_8: 'Gebruik van CamAPS FX bij jongvolwassenen en volwassenen',
    webinar_9: 'Het beheren van lichaamsbeweging, maaltijden en andere levensgebeurtenissen',
    webinar_10: 'Closed-loop-insulinetherapie en kwaliteit van leven',
    webinar_11: 'Closed-loop- therapie en al langgeschiedenis van diabetes type 1',
    webinar_footnote: '',

    contactDetailsHeader: 'Contactgegevens:',
    contactDetailsText: 'Ons ondersteuningsteam is tijdens kantooruren bereikbaar, maandag tot en met vrijdag van 9.00 tot 17.00 uur.',
    contactDetailsEmailTitle: 'Stuur ons een e-mail',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Neem contact met ons op',
    contactDetailsPhone: '+44 20 3695 3780 (Slechts Engels)',
    contactDetailsPhoneFull: 'tel.: +442036953780',

    eIfuAccess: 'Gedrukte exemplaren van de gebruikershandleidingen kunnen worden aangevraagd bij de ' +
        'klantenondersteuning. Gedrukte exemplaren zijn gratis en zullen binnen ongeveer 3 tot 7 dagen aankomen. ' +
        'Gebruik voor de beste resultaten Adobe® Reader® om CamDiab-gebruikershandleidingen te bekijken.',

    statusPageText: 'Statuspagina',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installeren CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Dana pomp gebruikers',
    ypsoPumpSupportText: 'YpsoPump gebruikers',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
